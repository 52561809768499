import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlbrowserService } from 'src/app/services/plbrowser.service';
import { MobiledetectService } from 'src/app/services/mobiledetect.service';


@Component({
  selector: 'app-vevoknek',
  templateUrl: './vevoknek.component.html',
  styleUrls: ['./vevoknek.component.css']
})
export class VevoknekComponent implements OnInit {

  list = [];
  desc = [];
  activeIndex;
  BrowserService: PlbrowserService;
  MobileService: MobiledetectService;
  isMobile : boolean;
  isBrowser : boolean;

  constructor(private MobiledetectService: MobiledetectService, private plbrowserService: PlbrowserService) {
    this.MobileService = MobiledetectService;
    this.BrowserService = plbrowserService;
    this.isBrowser = plbrowserService.isBrowser;
    this.isMobile = this.MobileService.isMobile;
  }

  ngOnInit(): void{
    this.activeIndex = 0;
    this.MobileService.detectMobile();
    this.isMobile = this.MobileService.isMobile;
  }

  @HostListener('window:resize', [])

  onResize () {
    this.MobileService.detectMobile();
    this.isMobile = this.MobileService.isMobile;
  }

  changeActive(index) {
    this.activeIndex = index;
  }

  /*constructor(@Inject(DOCUMENT) private document: Document, private plbrowserService: PlbrowserService  ) { }

  @HostListener('window:resize', [])

  onWindowResize() {
    this.detectMobile();
  }

  detectMobile() {
    if(this.isBrowser && window.matchMedia("(max-width: 768px)").matches) {
      this.isMobile = true;
      console.log('true');
  } else {
    this.isMobile = false;
    console.log('false');
  }

  }

  ngOnInit(): void {
    this.activeIndex = 0;
    this.detectMobile();
  }*/

}
