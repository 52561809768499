<div class="wrap">
  <div *ngIf="!isMobile" class="toolbar">
    <div class="links">
      <div>
        <div class="elem" [class.active]="activeIndex === 0" (click)="changeActive(0)">Netfaktor előnyei Eladók számára</div>
        <div class="elem" [class.active]="activeIndex === 1" (click)="changeActive(1)">Elektronikus adatszoba</div>
        <div class="elem" [class.active]="activeIndex === 2" (click)="changeActive(2)">Értékesítési eljárások</div>
        <div class="elem" [class.active]="activeIndex === 3" (click)="changeActive(3)">Kondíciók</div>
        <div class="elem" [class.active]="activeIndex === 4" (click)="changeActive(4)">3 hónap ingyenes Próbaidőszak</div>
      </div>
    </div>
  </div>
  <div class="content">
    <h1>Eladóknak</h1>
    <div *ngIf="activeIndex === 0 || isMobile">

    </div>
  </div>
</div>

