import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { SeoService } from './services/seo.service';
import { PlbrowserService } from './services/plbrowser.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    SeoService
  ],
})
export class AppComponent implements OnInit {

  constructor(private seoService: SeoService, private router: Router, private plbrowserService: PlbrowserService,) { }

  title = 'ps-netfaktor';
  schema = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Pannon Set Kft.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Fehérvári u. 24. IV./1',
      addressLocality: 'Budapest',
      postalCode: '1117',
      addressCountry: 'HU'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 47.473935,
      longitude: 19.046466
    },
    url: 'https://www.netfaktor.hu',
    email: 'ps@ps.hu',
    logo: 'https://www.netfaktor.hu/pannon-set-logo-micro.jpg',
    image: [
      'https://www.netfaktor.hu/og-image.png'
    ],
    telephone: '+36 1/789-0575'
  };

  gtagPageView() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // csak prod módban
        if (environment.production) {
          (window as any).dataLayer.push({
            event: 'pageviewcustomevent',
            pagePath: event.urlAfterRedirects,
            pageTitle: this.seoService.currentTitle
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.seoService.init();
    if (this.plbrowserService.isBrowser) {
      this.gtagPageView();
    }
  }
}
