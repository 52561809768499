<body>
    <div>
        <div class="pad">
            <h2 style="text-align: center"><strong>VEVŐKNEK</strong></h2>

            <p class="p-custom">
                A NETFAKTOR online követelésvásárlási platform kizárólag olyan vevőket fogad, akik Magyar Nemzeti Bank (MNB) engedéllyel rendelkeznek az üzletszerű követelésvásárláshoz.<br> Ezek a vevők lehetnek faktorcégek, befektetők, vagy bármely más
                olyan jogi személy, aki erre jogosult.
            </p>

            <ul class="margin-ul">
                <li style="font-size: 23px; margin-left: 15px;"><strong>A NETFAKTOR vevőinek típusai:</strong></li>
                <li><strong>Faktorcégek:</strong><br> A NETFAKTOR vevőinek legnagyobb csoportját a faktorcégek alkotják. Ezek a cégek tipikusan egész éven át aktívan vásárolnak követeléseket a platformon, akár havonta többször is.</li>
                <li><strong>Befektetők:</strong><br> A befektetők olyan magánszemélyek vagy jogi személyek, akik a NETFAKTOR platformot használják befektetési célból történő követelésvásárlásra. A befektetők célja a hozam elérése a követelések behajtásával.</li>
                <li><strong>Egyéb vevők:</strong><br> A NETFAKTOR platformon más vevők is vásárolhatnak követeléseket, mint pl. inkasszócégek, követeléskezelő cégek, vagy akár magánszemélyek is.</li>
            </ul>

            <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1 kep2" src="assets/img/undraw_hello_re_3evmV2.svg" />
            <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/undraw_business_deal_re_up4u.svg" />
            <!-- <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/Netfaktor_elado_illusztracio_1.svg" /> -->

        </div>
        <div class="blue-bg">
            <div class="pad">
                <p><strong>A NETFAKTOR tagság előnyei a vevők számára:</strong></p>
                <ul class="margin-ul">
                    <li><strong>Hozzáférés a piacra:</strong><br> A NETFAKTOR platformon a vevők azonnali hozzáférést kapnak a lejárt és nem lejárt követelések széles skálájához.
                    </li>
                    <li><strong>Hatékony keresés:</strong><br> A platform fejlett keresési funkciói lehetővé teszik a vevők számára, hogy gyorsan és egyszerűen megtalálják a kívánt követeléseket.
                    </li>
                    <li><strong>Átláthatóság:</strong><br> A NETFAKTOR platform átlátható árazást és tranzakciós folyamatot biztosít a vevők számára.
                    </li>
                    <li><strong>Kategorizálás:</strong><br> A követelések kategorizálva és strukturált formában kerülnek bemutatásra a platformon, ami megkönnyíti a vevők számára a keresést és az összehasonlítást.</li>

                </ul>

                <p style="padding-bottom: 50px;">
                    Ha Ön MNB engedéllyel rendelkező vevő, és szeretne a NETFAKTOR platformon követeléseket vásárolni, kérjük, vegye fel a kapcsolatot munkatársainkkal
                </p>

            </div>
        </div>

    </div>
</body>
