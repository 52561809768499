import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kapcsolat',
  templateUrl: './kapcsolat.component.html',
  styleUrls: ['./kapcsolat.component.css']
})

export class KapcsolatComponent implements OnInit {
  titlefromParent = 'Kérdése van?';
  workers = [
    {
      name : 'Fekete Zsolt',
      pos : 'ügyvezető',
      email : 'feketezs@netfaktor.hu',
      phone : '+36 20/495 1395'
    },
    // {
    //   name : 'Lesták Bence Márton',
    //   pos : 'ügyvezető',
    //   email : 'lestakb@netfaktor.hu',
    //   phone : '+36 20/770 5261'
    // }
  ];

  constructor() { }

  ngOnInit() {
  }

}
