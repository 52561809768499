import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { KapcsolatComponent } from './views/kapcsolat/kapcsolat.component';
import { AdatvedelemComponent } from './views/adatvedelem/adatvedelem.component';
import { TajekoztatoComponent } from './views/tajekoztato/tajekoztato.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { EladoknakComponent } from './views/eladoknak/eladoknak.component';
import { VevoknekComponent } from './views/vevoknek/vevoknek.component';
import { KovetelesekComponent } from './views/kovetelesek/kovetelesek.component';
import { TovabbiComponent } from './views/tovabbi/tovabbi.component';
import { TovabbiVevoComponent } from './views/tovabbivevo/tovabbivevo.component';
import { TovabbiMukodesComponent } from './views/tovabbimukodes/tovabbimukodes.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Netfaktor - címsor',
      description: 'Netfaktor leírás'
    }
  },
  {
    path: 'eladoknak',
    component: EladoknakComponent,
    data: {
      title: 'Eladóknak',
      description: 'Eladóknak leírás'
    }
  },
  {
    path: 'vevoknek',
    component: VevoknekComponent,
    data: {
      title: 'Vevőknek',
      description: 'Vevőknek leírás'
    }
  },
  {
    path: 'kovetelesek',
    component: KovetelesekComponent,
    data: {
      title: 'Követelések',
      description: 'Követelések leírás'
    }
  },
  {
    path: 'tajekoztato',
    component: TajekoztatoComponent,
    data: {
      title: 'Tajékoztató',
      description: 'Tájékoztató leírás'
    }
  },
  {
    path: 'kapcsolat',
    component: KapcsolatComponent,
    data: {
      title: 'Kapcsolat',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'tovabbi',
    component: TovabbiComponent,
    data: {
      title: 'További',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'tovabbivevo',
    component: TovabbiVevoComponent,
    data: {
      title: 'TovábbiVevo',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'tovabbimukodes',
    component: TovabbiMukodesComponent,
    data: {
      title: 'TovabbiMukodes',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'adatvedelem',
    component: AdatvedelemComponent,
    data: {
      title: 'Adatvédelem',
      metarobots: 'noindex,nofollow'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: '404',
      metarobots: 'noindex,nofollow'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,
    // enableTracing: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: "reload",
    scrollOffset: [80, 0] // [x, y]
    ,
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
