import { Component, OnInit } from '@angular/core';
import { parse } from 'path';
import { PlbrowserService } from '../../services/plbrowser.service';

@Component({
  selector: 'app-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.css']
})
export class NumbersComponent implements OnInit {

  constructor(private plbrowserService: PlbrowserService) { }
  animatednumberInt = 500;
  animatednumber() {
    const start = 0;
    const end = this.animatednumberInt;
    const range = end - start;
    let current = start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(4000 / range));
    const $this = this;
    const timer = setInterval(function () {
      current += increment;
      $this.animatednumberInt = current;
      if (current == end) {
        clearInterval(timer);
      }
    }, stepTime);
  }

  onInViewportChange(inViewport: boolean) {
    if (this.plbrowserService.isBrowser) {

      if (inViewport) {
        this.animatednumber();
      } else {

      }
    }
  }

  ngOnInit() {
    let now = new Date();

    let datetime = now.toLocaleString("en-us", { year: "numeric" });

    // Insert date and time into HTML
    document.getElementById("datetime").innerHTML = datetime;
  }

}
