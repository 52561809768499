<body>
    <div>
        <div class="pad">
            <h2 style="text-align: center"><strong>MŰKÖDÉS</strong></h2>
            <ul class="margin-ul">
                <p><strong>A Netfaktor működése</strong></p>
                <p>A Netfaktor egy online platform, amely összeköti a lejárt követelésekkel rendelkező eladókat a befektetőkkel és faktorcégekkel. A platform célja, hogy megkönnyítse a követelések gyors és hatékony értékesítését, és a befektetők számára
                    pedig vonzó befektetési lehetőségeket kínáljon.</p>

            </ul>
            <ul class="margin-ul">
                <p><strong>Felhasználási feltételek:</strong></p>
                <li>A rendszerhez kizárólag szerződött/regisztrált partnerek férhetnek hozzá. Jelenleg a rendszerhez 20 eladó (bank, közüzem, távközlési társaság, stb) és 107 MNB engedéllyel rendelkező faktortársaság fér hozzá (akik eladók is lehetnek.</li>
                <li>A rendszer használatához internetelérése és böngésző használat szükséges.</li>
            </ul>

            <ul class="margin-ul">
                <p><strong>Követelések meghirdetése:</strong></p>
                <li>A hitelezők egyedi követeléseiket és követeléscsomagjaikat is meghirdethetik a platformon.</li>
                <li>Célszerű Excel táblázatban összesíteni a és közzé tenni követeléseket (elektronikus adatszoba) a meghirdetés előtt.</li>
                <li>A hitelezők bármilyen releváns adatot feltüntethetnek a követelésekről.</li>
            </ul>

            <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1 kep2" src="assets/img/undraw_hello_re_3evmV2.svg" />
            <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/undraw_business_deal_re_up4u.svg" />
            <!-- <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/Netfaktor_elado_illusztracio_1.svg" /> -->


        </div>
        <div class="blue-bg">
            <div class="pad">


                <ul>
                    <p><strong>Eladói korlátozások:</strong></p>
                    <li>Az eladó korlátozhatja a követeléseihez hozzáférők körét.</li>
                    <li>Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.</li>
                    <li>Egyedi követelés szintjén is meghatározhatja, kiket hív meg az árverésre.</li>
                </ul>

                <ul>
                    <p><strong>Értesítések:</strong></p>
                    <li>Az eladó korlátozhatja a követeléseihez hozzáférők körét.</li>
                    <li>Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.</li>
                    <li>Egyedi követelés szintjén is meghatározhatja, kiket hív meg az árverésre.</li>
                </ul>

                <ul>
                    <p><strong>Licitálás / Döntéshozatal:</strong></p>
                    <li>
                        A hitelező kiválaszthatja az értékesítési módot (pl. pályázatos hirdetés, vaklicit, normál licit, TOP5 licit, japán licit, árejtés, stb.).
                    </li>
                    <li>
                        A licit időtartama szabadon meghatározható.
                    </li>
                    <li>
                        A licit lépcső beállítható, a rendszer javaslatot tesz a beállítható értékekre, de a hitelező szabadon módosíthatja azokat.
                    </li>
                    <li>
                        A hitelező a beérkezett legmagasabb ajánlat elfogadásáról és elutasításáról dönthet. Nincs kötelezettség a legmagasabb ajánlat elfogadására és szerződéskötésre.
                    </li>
                </ul>

                <ul>
                    <p><strong>Riportkészítés:</strong></p>
                    <li>
                        A Netfaktor minden üzleti információt tárol.
                    </li>
                    <li>
                        A lezárt követelésekre kapott legjobb ajánlatokat bármikor visszakeresheti és ellenőrizheti a hitelező.
                    </li>
                    <li>
                        A program riportolási és listázási lehetőségei szabadon szerkeszthetők.
                    </li>
                </ul>

            </div>
        </div>



    </div>
</body>
