<body>
    <div>
        <div class="pad">
            <h2 style="text-align: center"><strong>ELADÓKNAK</strong></h2>
            <p style="padding-bottom: 5px;">
                A NETFAKTOR online platformja két fő csoportot hoz össze: az eladókat és a hitelezőket.
            </p>

            <p><strong>Eladók:</strong></p>

            <p>
                Minden jogi és magánszemély, aki lejárt vagy nem lejárt követeléssel rendelkezik a '+' szerződött partnerével szemben, és azt értékesíteni kívánja.
            </p>
            <p><strong>Lejárt piacon:</strong></p>
            <ul class="lejartpiac-ul">
                <li>Banki hitel</li>
                <li>Lízing</li>
                <li>Közüzemi díj</li>
                <li>Szolgáltatási díj</li>
                <li>Áruhitel</li>
                <li>Diákhitel</li>
                <li>Hitelkártya</li>
                <li>Bérleti díj</li>
                <br>
            </ul>
        </div>

        <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1 kep2" src="assets/img/undraw_hello_re_3evmV2.svg" />
        <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/undraw_business_deal_re_up4u.svg" />
        <!-- <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1" src="assets/img/Netfaktor_elado_illusztracio_1.svg" /> -->
        <img _ngcontent-serverapp-c20="" alt="Eladóknak" class="kep1 kep3" src="assets/img/undraw_choose_re_7d5a.svg" />

        <div class="blue-bg">
            <div class="pad">
                <p>
                    <strong>A NETFAKTOR a regisztrált felek számára számos előny áll rendelkezésre, többek között:</strong>
                </p>
                <p><strong>Széles vevőkör:</strong></p>
                <ul class="margin-ul">
                    <li>
                        A platformon regisztrált hitelezők széles körű befektetői bázist képviselnek, akik különböző típusú követelések iránt érdeklődnek.
                    </li>
                </ul>

                <p><strong>Gyors értékesítés:</strong></p>
                <ul class="margin-ul">
                    <li>
                        A platformon a követelések gyorsan és egyszerűen értékesíthetők, a bonyolult adminisztratív procedúrák mellőzésével.
                    </li>
                </ul>
                <p><strong>Versenyképes árak:</strong></p>
                <ul class="margin-ul">
                    <li>
                        A platformon a követelések piaci áron értékesíthetők, a licitálási mechanizmusnak köszönhetően.
                    </li>
                </ul>
                <p><strong>Biztonságos tranzakciók:</strong></p>
                <ul class="margin-ul">
                    <li>
                        A platformon a tranzakciók biztonságosan bonyolíthatók le, a platform által biztosított garanciáknak köszönhetően.
                    </li>
                </ul>
            </div>
        </div>

        <div class="pad">
            <p>
                <strong>Az eladói oldalon kétféle partnert különböztetünk meg:</strong>
            </p>
            <p><strong>1. Alkalmi partnerek:</strong></p>
            <ul class="margin-ul">
                <li>
                    Ebbe a csoportba azok a partnerek tartoznak, akik nem rendszeresen, hanem alkalmanként értékesítenek követeléseket a a NETFAKTOR-on.
                </li>
                <li>
                    Az alkalmi partnerek nem rendelkeznek éves keretszerződéssel, és egyedi tranzakciók keretében értékesítik követeléseiket.
                </li>
            </ul>

            <p><strong>2. Állandó partnerek:</strong></p>
            <ul class="margin-ul">
                <li>
                    Ebbe a csoportba azok a partnerek tartoznak, akik egész éven át folyamatosan, korlátlan mennyiségben értékesítik követeléseiket a NETFAKTOR-on.
                </li>
                <li>
                    Jellemzően éves keretszerződéssel rendelkeznek, ami biztosítja számukra a platform folyamatos használatát.
                </li>
                <li>
                    A legtöbb állandó partner szolgáltató cég, bank, közüzemi szolgáltató vagy lízingcég, akik nagyobb volumenben és folyamatosan kívánnak követeléseket értékesíteni. </li>
                <li>
                    Ezek a partnerek havonta több alkalommal hirdetnek meg követeléscsomagokat, és igénylik a piacon való állandó jelenlétet.
                </li>
            </ul>
        </div>

    </div>
</body>
