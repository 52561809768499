<div class="kapcsolat">
    <h1>Kapcsolat</h1>
    <div class="companyinfocont">
        <div class="companyinfo">
            <img alt="levelezo" src="assets/img/kapcsolat-boritek.svg">
            <span>
        ProEXCHANGE Kft.<br>
        1053 Budapest, Kecskeméti utca<br> 5. I. em. 5B.
      </span>
        </div>
        <div class="companyinfo">
            <img alt="bankszamla" src="assets/img/kapcsolat-epulet.svg">
            <span>
        Adószám: <br> 32051531-2-41<br>
        Bankszámla szám:<br>
        10918001-00000119-43880002
      </span>
        </div>
        <div class="companyinfo">
            <img alt="email" src="assets/img/kapcsolat-mail.svg">
            <span>
        <a href="mailto:info@netfaktor.hu">info@netfaktor.hu</a><br>
      </span>
        </div>
        <div class="companyinfo">
            <img alt="telefon" src="assets/img/kapcsolat-mobil.svg">
            <span>
        <a href="tel:3617890575">+36 1/789-0575</a><br>
        <a href="tel:36204951395">+36 20/495 1395</a>
      </span>
        </div>
        <div class="companyinfo">
            <img alt="iroda" src="assets/img/kapcsolat-iroda.svg">
            <span>
        Iroda:<br>
        1117 Bp. Fehérvári út. 24. IV/1.
      </span>
        </div>
        <div class="companyinfo">
            <img alt="nyitvatartas" src="assets/img/kapcsolat-ora.svg">
            <span>
        Nyitvatartás:<br>
        munkanapokon 9-17 óra között
      </span>
        </div>
    </div>
    <div class="formcontainer">
        <app-kapcsolaturlap [formtitle]="titlefromParent"></app-kapcsolaturlap>
    </div>
    <div class="sep">
    </div>
    <div class="workercont">
        <div class="worker" *ngFor="let worker of workers">
            <div class="name">{{worker.name}}</div>
            <div class="position">{{worker.pos}}</div>
            <div class="email">
                <img src="assets/img/kapcsolat-boritek-black.svg">
                <span>E-mail:</span> <a href="mailto:{{worker.email}}">{{worker.email}}</a>
            </div>
            <div class="mobil">
                <img src="assets/img/kapcsolat-mobil-black.svg">
                <span>Mobil:</span> <a href="tel:{{worker.phone}}">{{worker.phone}}</a>
            </div>
        </div>
    </div>
</div>
