<div class="cont" id="szolgaltatas" snInViewport (inViewportChange)="onInViewportChange($event)">
    <div class="innercont">
        <div class="h-tags">
          <!--<h2>Esettanulmányok</h2>
          <h2>Netfaktor működése, szolgáltatásai</h2>-->
        </div>
        <h2>Miért előnyös a NETFAKTOR?</h2>
        <div class="swiper-button-prev"></div>
        <div class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">
                <div *ngFor="let slide of slides" class="swiper-slide">
                    <div class="imagecont">
                        <div class="image" style="background-image: url('{{slide.img}}');">
                        </div>
                    </div>
                    <h3>{{slide.title}}</h3>
                    <div class="text">
                        {{slide.desc}}
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-next"></div>
    </div>
</div>
