import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Custom extras
import { SwiperModule } from 'ngx-swiper-wrapper';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AgmCoreModule } from '@agm/core';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxJsonLdModule } from 'ngx-json-ld';

// Components
import { HomeComponent } from './views/home/home.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { KapcsolatComponent } from './views/kapcsolat/kapcsolat.component';
import { NavmenuComponent } from './components/navmenu/navmenu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScrolltopComponent } from './components/scrolltop/scrolltop.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { KapcsolaturlapComponent } from './components/kapcsolaturlap/kapcsolaturlap.component';
import { MapsComponent } from './components/maps/maps.component';
import { ModalComponent } from './components/modal/modal.component';
import { AdatvedelemComponent } from './views/adatvedelem/adatvedelem.component';
import { BevezetoComponent } from './components/bevezeto/bevezeto.component';
import { TajekoztatoComponent } from './views/tajekoztato/tajekoztato.component';
import { NumbersComponent } from './components/numbers/numbers.component';
import { BelepesComponent } from './components/belepes/belepes.component';
import { TajekoztatoshortComponent } from './components/tajekoztatoshort/tajekoztatoshort.component';
import { RegisztraciomeneteComponent } from './components/regisztraciomenete/regisztraciomenete.component';
import { WelcomeblockComponent } from './components/welcomeblock/welcomeblock.component';
import { CegunkrolComponent } from './components/cegunkrol/cegunkrol.component';
import { SzolgaltatasComponent } from './components/szolgaltatas/szolgaltatas.component';
import { I18nModule } from './i18n/i18n.module';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { PartnerekComponent } from './components/partnerek/partnerek.component';
import { EladoknakComponent } from './views/eladoknak/eladoknak.component';
import { VevoknekComponent } from './views/vevoknek/vevoknek.component';
import { KovetelesekComponent } from './views/kovetelesek/kovetelesek.component';
import { TovabbiComponent } from './views/tovabbi/tovabbi.component';
import { TovabbiVevoComponent } from './views/tovabbivevo/tovabbivevo.component';
import { SliderComponent } from './components/slider/slider.component';
import { HomesliderComponent } from './components/homeslider/homeslider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    KapcsolatComponent,
    NavmenuComponent,
    HeaderComponent,
    FooterComponent,
    ScrolltopComponent,
    TestimonialComponent,
    KapcsolaturlapComponent,
    MapsComponent,
    ModalComponent,
    AdatvedelemComponent,
    BevezetoComponent,
    TajekoztatoComponent,
    NumbersComponent,
    BelepesComponent,
    TajekoztatoshortComponent,
    RegisztraciomeneteComponent,
    WelcomeblockComponent,
    CegunkrolComponent,
    SzolgaltatasComponent,
    SelectLanguageComponent,
    PartnerekComponent,
    EladoknakComponent,
    VevoknekComponent,
    KovetelesekComponent,
    TovabbiComponent,
    SliderComponent,
    TovabbiVevoComponent,
    HomesliderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SimpleModalModule,
    SwiperModule,
    InViewportModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({ apiKey: environment.gmapApiURL }),
    NgxJsonLdModule,
    BrowserAnimationsModule,
    I18nModule
  ],
  providers: [
  ],
  entryComponents: [
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
