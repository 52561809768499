<div class="top triangle">

</div>

<div class="wrap">
  <div class="content">
    <div class="innercont">
      <div [id]="section.key" *ngFor="let section of sections; let i = index" class="row" snInViewport snOutViewport (inViewportChange)="onInViewportChange([$event, section.key])">
        <div>
          <div class="number">0{{i + 1}}</div>
          <div class="title">{{section.name}}</div>
          <div [innerHTML]="section.desc" class="desc"></div>
          <a [routerLink]="section.link">{{section.more}}</a>
        </div>
        <div [class.reverse]="i % 2">
          <img alt="{{section.name}}" src="{{section.img}}">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom triangle">

</div>
