<div class="fullwidthbgcont" snInViewport (inViewportChange)="onInViewportChange($event)">
    <div class="cont">
        <div class="innercont">
            <div class="numberbox">
                <img class="giftbox" src="assets/img/gift-box.svg" alt="">
                <div class="animatednumbers">
                    <span id="datetime"></span>-ben a NETFAKTOR rendszerben több mint <span>{{animatednumberInt}}</span> követelés csomag került értékesítésre.
                </div>
            </div>
        </div>
    </div>
    <img class="bottom" src="assets/img/numbers-bottom.svg" alt="">
</div>