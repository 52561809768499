import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MobiledetectService } from 'src/app/services/mobiledetect.service';
import { PlbrowserService } from 'src/app/services/plbrowser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eladoknak',
  templateUrl: './eladoknak.component.html',
  styleUrls: ['./eladoknak.component.css']
})
export class EladoknakComponent implements OnInit {

  list = [];
  desc = [];
  activeIndex;
  BrowserService: PlbrowserService;
  MobileService: MobiledetectService;
  isMobile : boolean;
  isBrowser : boolean;

  constructor(private MobileDetectService: MobiledetectService, private plbrowserService: PlbrowserService) {
    this.MobileService = MobileDetectService;
    this.BrowserService = plbrowserService;
    this.isBrowser = plbrowserService.isBrowser;
    this.isMobile = this.MobileService.isMobile;
  }

  ngOnInit(): void {
    this.activeIndex = 0;
    this.MobileService.detectMobile();
    this.isMobile = this.MobileService.isMobile;
  }

  changeActive(index) {
    this.activeIndex = index;
  }

  @HostListener('window:resize', [])

  onResize() {
    this.MobileService.detectMobile();
    this.isMobile = this.MobileService.isMobile;
  }

}
