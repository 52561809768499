import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { PlbrowserService } from '../../services/plbrowser.service';

@Component({
  selector: 'app-homeslider',
  templateUrl: './homeslider.component.html',
  styleUrls: ['./homeslider.component.css']
})
export class HomesliderComponent implements OnInit {

  slides = [];

  constructor(private plbrowserService: PlbrowserService) { }
  onInViewportChange(inViewport: boolean) {
    /*if (this.plbrowserService.isBrowser) {
      if (inViewport) {
        document.querySelector('[fragment="szolgaltatas"]').classList.add('active');
      } else {
        document.querySelector('[fragment="szolgaltatas"]').classList.remove('active');
      }
    }*/
  }

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.001,
    speed: 1600,
    pagination: true,
    loop: true,
    keyboard: true,
    lazy: true,
    navigation: true,
    spaceBetween: 40,
    centeredSlides: true,
    watchOverflow: true,
    autoplay: {
      delay: 6200,
      disableOnInteraction: false,
    },
    breakpoints: {
      1300: {
        slidesPerView: 1
      }
    }
  };


  ngOnInit() {
    this.slides = [
      {
        //assets/img/face2.gif
        img: 'assets/img/undraw_hello_re_3evm.svg',
        title: 'ONLINE Követelés PIAC',
        desc: 'A NETFAKTOR egy helyre koncentrálja a követelés eladókat és vevőket, így a piaci szereplők egy helyen és időben egyszerre vannak jelen.',
      },
      {
        img: 'assets/img/welcome-drawing.svg',
        title: 'Legjobb piaci feltételek',
        desc: 'A NETFAKTOR-ban a 120 regisztrált faktor cég megversenyeztetésével a legmagasabb vételár érhető el.',
      },
      {
        img: 'assets/img/gifgit.gif',
        title: 'Hatékony Értékesítés',
        desc: 'A NETFAKTOR - a követelések meghirdetése gyors, biztonságos, és hatékony. Az értékesítési folyamat , a kiválasztás módja átlátható.',
      },
      // {
      //   img: 'assets/img/Netfaktor_icon_07.svg',
      //   title: 'Értéknövelés',
      //   desc: 'elemzésekkel, tanácsadássa támogatjuk a legjobb feltétek elérése érdekébe',
      // }
    ]
  }

}
