import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { PlbrowserService } from '../../services/plbrowser.service';

@Component({
  selector: 'app-szolgaltatas',
  templateUrl: './szolgaltatas.component.html',
  styleUrls: ['./szolgaltatas.component.css']
})
export class SzolgaltatasComponent implements OnInit {

  slides = [];

  constructor(private plbrowserService: PlbrowserService) { }
  onInViewportChange(inViewport: boolean) {
    /*if (this.plbrowserService.isBrowser) {
      if (inViewport) {
        document.querySelector('[fragment="szolgaltatas"]').classList.add('active');
      } else {
        document.querySelector('[fragment="szolgaltatas"]').classList.remove('active');
      }
    }*/
  }

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    speed: 1600,
    pagination: true,
    loop: true,
    keyboard: true,
    lazy: true,
    navigation: true,
    spaceBetween: 40,
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1100: {
        slidesPerView: 1
      }
    }
  };

  ngOnInit() {
    this.slides = [
      {
        img: 'assets/img/Netfaktor_icon_06.svg',
        title: 'Követelések értékesítése',
        desc: 'a teljes faktorpiac megversenyeztetésével történik',
      },
      {
        img: 'assets/img/Netfaktor_icon_01.svg',
        title: 'Legjobb piaci feltételek',
        desc: 'a megfelelő értékesítése folyamat és a teljes körű faktorpiac megversenyeztetésével a legmagasabb vételár érhető el',
      },
      {
        img: 'assets/img/Netfaktor_icon_03.svg',
        title: 'Átlátható prudens értékesítési folyamat',
        desc: 'nem kérdőjelezhető meg a kiválasztás módja, a vevő személyes és a vételár összege',
      },
      {
        img: 'assets/img/Netfaktor_icon_04.svg',
        title: 'Biztonságos, gyors, kényelmes (hatékony) értékesítés',
        desc: 'a Netfaktorban a követelések meghirdetése, értékesítése gyors, biztonságos és hatékony',
      },
      {
        img: 'assets/img/Netfaktor_icon_07.svg',
        title: 'Értéknövelés',
        desc: 'elemzésekkel, tanácsadássa támogatjuk a legjobb feltétek elérése érdekébe',
      }
    ]
  }

}
