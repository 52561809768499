import { Component, OnInit } from '@angular/core';
import { GlobalConstanst } from 'src/app/common/global';

@Component({
  selector: 'app-partnerek',
  templateUrl: './partnerek.component.html',
  styleUrls: ['./partnerek.component.css']
})
export class PartnerekComponent implements OnInit {

  sections = [];

  constructor() { }

  ngOnInit(): void {
    this.sections = [
      {
        key: 'eladoknak',
        name: 'Eladóknak',
        img: 'assets/img/Netfaktor_elado_illusztracio_1.svg',
        desc: 'Kik az eladók?<br><br>Az On-line követeléspiacon minden olyan jogi és magánszemély eladó lehet, aki a szerződött partnerével szemben követeléssel rendelkezik, és azt értékesíteni kívánja.',
        more: 'További információ Eladóknak',
        link: 'tovabbi'
      },
      {
        key: 'vevoknek',
        name: 'Vevőknek',
        img: 'assets/img/Netfaktor_vevo_illusztracio_1.svg',
        desc: 'Kik a vevők?<br><br>Az On-line követeléspiacon minden olyan faktorcég vevő lehet, aki MNB engedéllyel rendelkezik, valamint jogosult követelések üzletszerű vásárlására.',
        more: 'További információ Vevőknek',
        link: 'tovabbivevo'
      },
      {
        key: 'kovetelesek',
        name: 'Működés',
        img: 'assets/img/Netfaktor_koveteles_illusztracio_1.svg',
        desc: 'A NETFAKTOR -ban az eladók a szerződött partnereik lejárt tartozásait/értékesítik. Ezek a követelések, amelyek lehetnek: banki hitel, lízing, közüzemi, szolgáltatási díj, áruhitel, hitelkártya, bérleti díj, stb.',
        more: 'További információ a Működésről',
        link: 'tovabbimukodes'
      }
    ]
  }

  onInViewportChange(params) {
    const inViewPort = params[0];
    const key = params[1];
    GlobalConstanst.menu.map(m => {
      if(m.id === key) m.active = inViewPort;
    });
  }

}
