<h2>{{formtitle}}</h2>
<form [formGroup]="kapcsolatForm" class="kapcsform" (ngSubmit)="onSubmit()" *ngIf="!submitted">
    <div class="formrow">
        <input id="nev" type="text" name="nev" required formControlName="nev" placeholder="Név"
            [class.err]="errors.nev">
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.nev">{{errors.nev}}</div>
    </div>
    <div class="formrow">
        <input id="cegnev" type="text" name="cegnev" formControlName="cegnev" placeholder="Cégnév">
    </div>
    <div class="formrow">
        <input id="email" type="text" name="email" formControlName="email" placeholder="E-mail cím"
            [class.err]="errors.email">
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.email">{{errors.email}}</div>
    </div>
    <div class="formrow">
        <input id="mobil" type="text" name="mobil" formControlName="mobil" placeholder="Mobiltelefonszám">
    </div>
    <div class="formrow">
        <textarea name="" id="megjegyzes" name="megjegyzes" formControlName="megjegyzes" cols="30" rows="5"
            placeholder="Megjegyzés"></textarea>
    </div>
    <div class="formrow">
        <input type="checkbox" name="aszf" id="aszf" formControlName="aszf" required>
        <label for="aszf">Elolvastam és elfogadom az <a routerLink="/adatvedelem" target="_blank">adatvédelmi nyilatkozatban</a>
            foglaltakat.</label>
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.aszf">{{errors.aszf}}</div>
    </div>
    <button type="submit">Küldés</button> <!-- [disabled]="!kapcsolatForm.valid" -->
</form>
<div *ngIf="submitted" class="thankyou">
    <span class="name">Kedves {{kapcsolatForm.value.nev}}!</span><br>
    Köszönjük a kapcsolatfelvételt! Munkatársunk hamarosan jelentkezik.
</div>