import { Component, OnInit } from '@angular/core';
import { GlobalConstanst } from 'src/app/common/global';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})

export class NavmenuComponent implements OnInit {

  menu = [];

  constructor(
  ) { }

  ngOnInit() {
    this.menu = GlobalConstanst.menu;
  }

}
