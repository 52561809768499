<footer>
    <div class="footer-colcont">
        <div class="col net">
            <a routerLink="/">
                <img class="netfaktorlogo" src="assets/img/logo-footer.svg" alt="Netfaktor">
            </a>
            <p>Amennyiben szeretne teljes körű tájékoztatást és részletes ismertetést kapni a rendszer működéséről
                készséggel állunk rendelkezésre.</p>
            <div class="pslinkcont">
                <a class="pslink" href="https://proexchange.hu">www.proexchange.hu</a>
            </div>
        </div>
        <div class="col kapcs">
            <h3>Kapcsolat</h3>
            <p>ProEXCHANGE Kft.<br>
                1053 Budapest, Kecskeméti utca 5. I. em. 5B.<br>
                Telefonszám: +36 1/789-0575<br>
                E-mail: info@netfaktor.hu<br>
                Mobil: +36 20/495 1395
            </p>
            <p>Nyitvatartás:<br>
                munkanapokon 9-17 óra között</p>
        </div>
       <!-- <div class="col map">
            <app-maps></app-maps>
            <div class="credits">© {{year}} Netfaktor. Minden jog fenntartva.</div>
        </div>-->
    </div>
</footer>
