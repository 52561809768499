<div class="wrap">
  <div *ngIf="!isMobile" class="toolbar">
    <div class="links">
      <div>
        <div class="elem" [class.active]="activeIndex === 0" (click)="changeActive(0)">Netfaktor Vevői</div>
        <div class="elem" [class.active]="activeIndex === 1" (click)="changeActive(1)">Koncentrált Követelés piac</div>
        <div class="elem" [class.active]="activeIndex === 2" (click)="changeActive(2)">Elektronikus adatszoba</div>
        <div class="elem" [class.active]="activeIndex === 3" (click)="changeActive(3)">Automatikus értesítés</div>
        <div class="elem" [class.active]="activeIndex === 4" (click)="changeActive(4)">Regisztráció menete</div>
      </div>
    </div>
  </div>
  <div class="content">
    <h1>Követelések</h1>
    <div *ngIf="activeIndex === 0 || isMobile" >
      <h2 *ngIf="isMobile">Netfaktor Vevői</h2>
      <ul>
        <li>Bankok, hitelszövetkezetek, hitelbiztosítók</li>
        <li>Biztosítók</li>
        <li>Lízingcégek</li>
        <li>Faktorcégek</li>
        <li>Önkormányzatok</li>
        <li>Közüzemi szolgáltatók</li>
        <li>Jogi személyes (cégek)</li>
        <li>Magánszemélyek</li>
      </ul>
    </div>
    <div *ngIf="activeIndex === 2 || isMobile" >
      <h2 *ngIf="isMobile">Elektronikus adatszoba</h2>
      <ul>
        <li>E-adatszoba - Áttekinthető, biztonságos, csak a szerződő felek számára hozzáférhető a követelések és
          kapcsolódó dokumentumok online megtekinthetőek</li>
      </ul>
    </div>
  </div>
</div>
