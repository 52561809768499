export class GlobalConstanst {
  public static menu = [
    {
      id: 'eladoknak',
      name: 'Eladóknak',
      link: '/eladoknak',
      active: false,
    },
    {
      id: 'vevoknek',
      name: 'Vevőknek',
      link: '/vevoknek',
      active: false,
    },
    {
      id: 'kovetelesek',
      name: 'Működés',
      link: '/kovetelesek',
      active: false,
    }
  ];
}
