import { Injectable } from '@angular/core';
import { PlbrowserService } from './plbrowser.service';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobiledetectService {

  isMobile : boolean;
  isBrowser = this.plbrowserService.isBrowser;

  constructor(@Inject(DOCUMENT) private document: Document, private plbrowserService: PlbrowserService) { }

  /*private myFunc: () => void;
  detectMobile(fn: () => void) {
    this.myFunc = fn;
  }*/

  onWindowResize() {
    this.detectMobile();
    console.log('owr');
  }

  detectMobile() {
    if(this.isBrowser && window.matchMedia("(max-width: 768px)").matches) {
      this.isMobile = true;
      console.log('true');
    } else {
    this.isMobile = false;
    console.log('false');
    }
  }



}
